@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-VF.woff2') format('woff2-variations');
  font-weight: 125 950;
  font-stretch: 75% 125%;
}

// * { --scrollbar-border-thickness:0px; --scrollbar-corner-color:#FFFFFF; --scrollbar-border-radius:20px; --scrollbar-height:10px; --scrollbar-width:10px; --scrollbar-thumb-color:#46BFF5; --scrollbar-track-color:#FFFFFF; } *::-webkit-scrollbar { width: var(--scrollbar-width, 20px); height: var(--scrollbar-height, 20px); }*::-webkit-scrollbar-thumb { background: var(--scrollbar-thumb-color, #3B82F6); border: var(--scrollbar-border-thickness, 3px) solid var(--scrollbar-border-color, rgb(255, 255, 255)); border-radius: var(--scrollbar-border-radius, 4px); }*::-webkit-scrollbar-track { background: var(--scrollbar-track-color, #A1A1AA); }*::-webkit-scrollbar-corner { background: var(--scrollbar-corner-color, #FFFFFF); border: var(--scrollbar-border-thickness, 3px) solid var(--scrollbar-border-color, rgb(255, 255, 255)); border-radius: var(--scrollbar-border-radius, 4px); }*::-webkit-scrollbar-button:vertical:start:increment, #preview::-webkit-scrollbar-button:vertical:end:decrement, #preview::-webkit-scrollbar-button:horizontal:start:increment, #preview::-webkit-scrollbar-button:horizontal:end:decrement { display: var(--show-double-buttons, none); }
* {
  --scrollbar-border-thickness: 0px;
  --scrollbar-corner-color: #ffffff;
  --scrollbar-border-radius: 20px;
  --scrollbar-height: 10px;
  --scrollbar-width: 10px;
  --scrollbar-thumb-color: #46bff5;
  --scrollbar-track-color: #ffffff;
}

*::-webkit-scrollbar {
  width: var(--scrollbar-width, 20px);
  height: var(--scrollbar-height, 20px);
}

*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color, #3b82f6);
  border: var(--scrollbar-border-thickness, 3px) solid var(--scrollbar-border-color, rgb(255, 255, 255));
  border-radius: var(--scrollbar-border-radius, 4px);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color, #a1a1aa);
}

*::-webkit-scrollbar-corner {
  background: var(--scrollbar-corner-color, #ffffff);
  border: var(--scrollbar-border-thickness, 3px) solid var(--scrollbar-border-color, rgb(255, 255, 255));
  border-radius: var(--scrollbar-border-radius, 4px);
}

*::-webkit-scrollbar-button:vertical:start:increment,
#preview::-webkit-scrollbar-button:vertical:end:decrement,
#preview::-webkit-scrollbar-button:horizontal:start:increment,
#preview::-webkit-scrollbar-button:horizontal:end:decrement {
  display: var(--show-double-buttons, none);
}
